.parsed-link {
    text-decoration: none;
}

body.chakra-ui-dark .parsed-link {
    color: #79C62B !important;
}

body.chakra-ui-light .parsed-link {
    color: #79C62B !important;
}

.parsed-link:hover {
    text-decoration: underline;
}